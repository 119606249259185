import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, Grid, ListItem } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import StockTable from "./Table";
import TextField from "@mui/material/TextField";
import { AllContext } from "../context";
import { useContext } from "react";

export default function ThirdRow() {
  const data = useContext(AllContext);

  console.log(data);
  const [search, setSearch] = React.useState("");

  return (
    <Grid container spacing={2}>
      <Grid item xs={8} sx={{}}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 2,
            minHeight: "14rem",
            borderRadius: ".8rem",
          }}
        >
          <Box
            sx={{
              m: 1,
              mt: 0,
            }}
          >
            <Box sx={{ display: "flex", padding: "10px" }}>
              <Typography sx={{ flex: 1 }} variant="h5">
                Live Data
              </Typography>
              <TextField
                type={"text"}
                id="standard-basic"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                placeholder="Search"
                sx={{}}
              />
            </Box>

            <StockTable search={search} />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={4} sx={{}}>
        <Card
          sx={{
            p: "1.6rem 2.2rem",
            borderRadius: ".8rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <CardContent>
            <Typography sx={{ flex: 1 }} variant="h5">
              My Portfolio
              <Doughnut
                data={{
                  labels: data.myShares.map((s) => s.symbol),
                  datasets: [
                    {
                      data: data.myShares.map((s) => s.quantity),
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.5)",
                        "rgba(54, 162, 235, 0.5)",
                        "rgba(255, 206, 86, 0.5)",
                        "rgba(75, 192, 192, 0.5)",
                        "rgba(153, 102, 255, 0.5)",
                        "rgba(255, 159, 64, 0.5)",
                      ],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    responsive: true,
                  },
                }}
              />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
