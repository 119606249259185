import React, { useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import data from "../data-08-06.json";
import { Button, Tooltip, Box } from "@mui/material";

import BuyPopup from "./BuyPopup";

import { DataGrid } from "@mui/x-data-grid";

function StockTable({ search }) {
  const [pageSize, setPageSize] = React.useState(25);
  const [buyPopupOpen, setBuyPopupOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const symbols = Object.keys(data);

  function createData(symbol, ltp, open, high, low, change) {
    return {
      id: Math.random(),
      symbol,
      ltp,
      open,
      high,
      low,
      change,
      buy_btn: symbol,
    };
  }

  React.useEffect(() => {
    const temp_rows = [
      ...symbols.map((symbol) =>
        createData(
          symbol,
          data[symbol].lp,
          data[symbol].op,
          data[symbol].h,
          data[symbol].l,
          data[symbol].c
        )
      ),
    ].filter(
      (r) =>
        data[r.symbol].n.toLowerCase().includes(search.toLowerCase()) ||
        data[r.symbol].s.toLowerCase().includes(search.toLowerCase())
    );
    setRows(temp_rows);
  }, [search]);

  return (
    <div style={{ height: "30rem" }}>
      <BuyPopup open={buyPopupOpen} setOpen={setBuyPopupOpen} />
      <DataGrid
        pageSize={25}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        data={rows}
        rows={rows}
        columns={[
          {
            field: "symbol",
            headerName: "Symbol",
            renderCell: ({ value }) => (
              <Box
                sx={{
                  cursor: "pointer",
                }}
              >
                <Tooltip title={data[value].n} placement="top">
                  <span>{value}</span>
                </Tooltip>
              </Box>
            ),
          },
          { field: "ltp", headerName: "LTP" },
          { field: "open", headerName: "Open" },
          { field: "high", headerName: "High" },
          { field: "low", headerName: "Low" },
          { field: "change", headerName: "Change" },
          // insert react component here
          {
            field: "buy_btn",
            headerName: "Buy",
            renderCell: ({ value }) => (
              <Button
                onClick={() => {
                  setBuyPopupOpen(value);
                }}
                variant="contained"
                color="primary"
              >
                Buy
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
}

export default StockTable;
