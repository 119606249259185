import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  DialogTitle,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import SellPopup from "./SellPopup";
import { AllContext } from "../context";

function Field({ label, value }) {
  return (
    <Box>
      {" "}
      <Typography sx={{ fontSize: "0.5rem", color: "gray" }}>
        {label}
      </Typography>
      <Typography sx={{ marginTop: "-5px" }}>{value}</Typography>
    </Box>
  );
}
export default function StockDialog({ open, setOpen }) {
  const { data, myShares } = React.useContext(AllContext);
  const [sellPopupOpen, setSellPopupOpen] = React.useState(false);
  const currentShare = myShares.find((share) => share.symbol === open);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SellPopup open={sellPopupOpen} setOpen={setSellPopupOpen} />
      <Dialog open={!!open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ flex: 1 }}>
              <Field label={open} value={open && data[open].n} />
            </Box>
            <Box sx={{ marginRight: "20px", fontSize: "0.7rem" }}>
              P/L :{" "}
              {open &&
                data[open].lp * currentShare.quantity -
                  currentShare.boughtltp * currentShare.quantity}
            </Box>
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  setSellPopupOpen(open);
                  setOpen(false);
                }}
              >
                Sell
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {" "}
          <Table sx={{}} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>
                  {" "}
                  <Field
                    label="LTP"
                    value={open && +data[open].lp.toFixed(2)}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Field
                    label="Quantity"
                    value={open && currentShare.quantity}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Field
                    label="Value"
                    value={
                      open &&
                      +(data[open].lp * currentShare.quantity).toFixed(2)
                    }
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Field
                    label="Bought At"
                    value={open && +currentShare.boughtltp.toFixed(2)}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Field
                    label="Bought P/S"
                    value={open && +currentShare.boughtCostPerShare.toFixed(2)}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Field label="Bought On" value={open && currentShare.date} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
