import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import StockPopup from "./StockPopup";
import { AllContext } from "../context";

function Stock({ color, symbol, profit, setStockPopup, quantity }) {
  return (
    <div>
      <Box
        sx={{
          backgroundColor: profit < 0 ? "#FD3F3F88" : "#B8ECD3",
          borderStyle: "solid",
          borderWidth: "0",
          borderRadius: "10px;",
          width: "100%",
          padding: "5px",
          paddingX: "15px",
          fontSize: "0.9rem",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          cursor: "pointer",
        }}
        onClick={() => {
          setStockPopup(symbol);
        }}
      >
        <div>{symbol}</div>
        <div>QTY: {quantity}</div>
      </Box>
    </div>
  );
}

function SecondRow() {
  const [stockPopup, setStockPopup] = React.useState(false);
  const { myShares, data } = React.useContext(AllContext);
  const style = {
    height: "100%",
    borderStyle: "solid",
    borderWidth: "0",
    borderRadius: "0.5rem",
  };

  function Profit({ amount, color }) {
    return (
      <Box
        sx={{
          ml: 1.5,
          borderStyle: "solid",
          borderWidth: 0,
          borderRadius: "100%",
          padding: "2px",
          width: "25px",
          height: "25px",
          backgroundColor: color,
          fontSize: "0.8rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "-7px",
        }}
      >
        {amount}
      </Box>
    );
  }
  return (
    <Grid container spacing={2}>
      <StockPopup open={stockPopup} setOpen={setStockPopup} />
      <Grid item xs={4}>
        <Card sx={style} elevation="2">
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ flex: 1 }} variant="h5">
                My Shares
              </Typography>
              <Typography variant="h6"> </Typography>
            </Box>
            <div style={{ marginTop: "20px" }}>
              {myShares.map((share) => (
                <Stock
                  color="#B8ECD3"
                  symbol={share.symbol}
                  profit={
                    data &&
                    data[share.symbol].lp * share.quantity -
                      share.boughtltp * share.quantity
                  }
                  quantity={share.quantity}
                  setStockPopup={setStockPopup}
                />
              ))}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={8}>
        <Card sx={style} elevation="2">
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Box sx={{ fontSize: "1.2rem", flex: 1, display: "flex" }}>
                <Box sx={{}}>NEPSE Chart</Box>
              </Box>
              <Box
                sx={{
                  float: "right",
                  color: "royalblue",
                  fontSize: "0.7rem",
                }}
              ></Box>
            </Box>

            <Box style={{}}>
              <Line
                datasetIdKey="id"
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                    responsive: true,
                  },
                }}
                data={{
                  labels: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                  ],
                  datasets: [
                    {
                      id: 2,
                      label: "NEPSE",
                      data: [2587, 2890, 2615, 2456, 2367, 2115, 2101, 2195],
                      backgroundColor: "#C5B8EC",
                      borderColor: "#C5B8EC",
                    },
                  ],
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SecondRow;
