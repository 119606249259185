import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  DialogTitle,
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Alert,
  Snackbar,
} from "@mui/material";
import { AllContext } from "../context";

export default function FormDialog({ open, setOpen }) {
  const [value, setValue] = React.useState(0);
  const { data, balance, setBalance, myShares, setMyShares } =
    React.useContext(AllContext);

  React.useEffect(() => {
    setValue(0);
  }, [open]);

  const ltp = open && data[open].lp;
  const amount = +ltp * value;

  const handleChange = (event) => {
    setValue(event.target.value < 0 ? 0 : event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [errorToastOpen, setErrorToastOpen] = React.useState(false);

  const handleErrorToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorToastOpen(false);
  };

  const [successToastOpen, setSuccessToastOpen] = React.useState(false);

  const handleSuccessToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessToastOpen(false);
  };

  // symbol, ltp, quantity, costPerShare, date
  const handleSell = () => {
    myShares.forEach((share) => {
      if (share.symbol === open) {
        if (+share.quantity > +value) {
          share.quantity -= +value;
          console.log(+balance + +amount);
          setBalance(+balance + +amount);
          setMyShares(myShares);
          setOpen(false);
          setSuccessToastOpen(true);
        } else if (+share.quantity === +value) {
          setBalance(+balance + +amount);
          setMyShares(myShares.filter((share) => share.symbol !== open));
          setOpen(false);
          setSuccessToastOpen(true);
        } else {
          setErrorToastOpen(true);
        }
      }
    }),
      setValue(0);
  };

  return (
    <div>
      <Snackbar
        open={errorToastOpen}
        autoHideDuration={6000}
        onClose={handleErrorToastClose}
      >
        <Alert variant="filled" severity="error">
          You don't have enough shares to sell!
        </Alert>
      </Snackbar>

      <Snackbar
        open={successToastOpen}
        autoHideDuration={6000}
        onClose={handleSuccessToastClose}
      >
        <Alert variant="filled" severity="success">
          You successfully sold the shares!
        </Alert>
      </Snackbar>

      <Dialog open={!!open} onClose={handleClose}>
        <DialogTitle>Sell Share</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To sell to this share, please enter amount here.
          </DialogContentText>

          <Table sx={{}} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Symbol
                </TableCell>
                <TableCell align="right">{open && data[open].s}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  LTP
                </TableCell>
                <TableCell align="right">
                  {open &&
                    "Rs. " +
                      ltp.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                      })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Quanity (Available:{" "}
                  {open &&
                    myShares.find((share) => share.symbol === open).quantity}
                  )
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    p: 0,
                  }}
                >
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "10ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      type={"number"}
                      id="standard-basic"
                      value={value}
                      onChange={handleChange}
                      size="small"
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Amount
                </TableCell>
                <TableCell align="right">
                  {open &&
                    "Rs. " +
                      amount.toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                      })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!+value} onClick={handleSell}>
            Sell
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
