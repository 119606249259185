import * as React from "react";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import { ContextProvider } from "./context";

export default function App() {
  return (
    <ContextProvider>
      <Dashboard />
    </ContextProvider>
  );
}
