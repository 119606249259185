import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import { AllContext } from "../context";

export default function FirstRow() {
  const { balance, equity, data } = React.useContext(AllContext);
  console.log(Object.keys(data));
  return (
    <Grid container spacing={2}>
      {[
        {
          header: "Balance",
          value:
            "Rs. " +
            (+balance).toLocaleString("en-IN", {
              maximumFractionDigits: 2,
            }),
          color: "#5865F288",
        },
        {
          header: "Equity",
          value:
            "Rs. " +
            (+equity).toLocaleString("en-IN", {
              maximumFractionDigits: 2,
            }),
          color: "#FD3F3F88",
        },
      ].map(({ header, value, color }, index) => (
        <Grid item xs={3} key={index}>
          <Card
            sx={{
              height: "9rem",
              p: "1.6rem 2.2rem",
              borderRadius: ".8rem",
              background: color,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="h5" sx={{ color: "white" }}>
              {header}
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontSize: "1.8rem", color: "white" }}
            >
              {value}
            </Typography>
          </Card>
        </Grid>
      ))}

      <Grid item xs={6}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "9rem",
            p: 2,
            borderRadius: ".8rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              pb: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#7DEC49",
                color: "white",
                borderRadius: ".6rem",
                p: ".2rem .8rem",
              }}
            >
              Market Open
            </Box>
            {[
              {
                header: "NEPSE Index",
                value: 2113.5,
                change: -3,
              },
              {
                header: "Hydro Index",
                value: 2113.5,
                change: -3,
              },
              {
                header: "Life Insurance",
                value: 2113.5,
                change: -3,
              },
              {
                header: "Non-Life",
                value: 2113.5,
                change: -3,
              },
            ].map(({ header, value, change }, index) => (
              <>
                <Box>
                  <Typography variant="body2" component="p">
                    {header}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {value}{" "}
                    <span
                      style={{
                        color: change < 0 ? "#F55151" : "#F34444",
                      }}
                    >
                      ({change}%)
                    </span>
                  </Typography>
                </Box>
                {index !== 3 && (
                  <Box
                    sx={{
                      width: "1px",
                      height: "80%",
                      backgroundColor: "#999",
                    }}
                  ></Box>
                )}
              </>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {[
              {
                number: Object.keys(data).filter(
                  (sym) =>
                    +data[sym].c / data[sym].op > 0 &&
                    +data[sym].c / data[sym].c < 10
                ).length,
                text: "Increased",
                color: "#7DEC49",
              },
              {
                number: Object.keys(data).filter(
                  (sym) =>
                    +data[sym].c / data[sym].op < 0 &&
                    +data[sym].c / data[sym].op > -10
                ).length,
                text: "Decreased",
                color: "#F34444",
              },
              {
                number: Object.keys(data).filter(
                  (sym) => +data[sym].c / data[sym].op == 0
                ).length,
                text: "Unchanged",
                color: "#5866E2",
              },
              {
                number: Object.keys(data).filter(
                  (sym) => +data[sym].c / data[sym].op >= 10
                ).length,
                text: "+ve Circuit",
                color: "#7DEC49",
              },
              {
                number: Object.keys(data).filter(
                  (sym) => +data[sym].c / data[sym].op <= -10
                ).length,
                text: "-ve Circuit",
                color: "#F34444",
              },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  color: item.color,
                }}
              >
                <Typography
                  variant="h5"
                  component="p"
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {item.number}
                </Typography>
                <Typography variant="body2" component="p">
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
