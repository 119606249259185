import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import FirstRow from "../components/FirstRow";
import SecondRow from "../components/SecondRow";
import ThirdRow from "../components/ThirdRow";
import Navbar from "../components/Navbar";

const Dashboard = () => {
  console.log(FirstRow);
  return (
    <>
      <Navbar />
      <Container sx={{ py: 6, marginTop: "70px" }}>
        <Typography
          variant="h4"
          sx={{
            color: "#5865F2",
            textTransform: "uppercase",
            marginBottom: "30px",
          }}
        >
          Dashboard
        </Typography>
        {[FirstRow, SecondRow, ThirdRow].map((Component, index) => (
          <Box
            key={index}
            sx={{
              py: 2,
            }}
          >
            <Component />
          </Box>
        ))}
      </Container>
    </>
  );
};

export default Dashboard;
