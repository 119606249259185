import { createContext, useReducer, useEffect } from "react";
import data from "../data-08-06.json";

const initialState = {
  data: [],
  balance: 0,
  equity: 0,
  myShares: [],
};

const AllContext = createContext({
  data: [],
  balance: 0,
  equity: 0,
  myShares: [],
  setData: (data) => {},
  setBalance: (balance) => {},
  setEquity: (equity) => {},
  setMyShares: (myShares) => {},
});

const allReducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload,
      };
    case "SET_BALANCE":
      return {
        ...state,
        balance: action.payload,
      };
    case "SET_EQUITY":
      return {
        ...state,
        equity: action.payload,
      };
    case "SET_MY_SHARES":
      return {
        ...state,
        myShares: action.payload,
      };
    default:
      return state;
  }
};

const ContextProvider = (props) => {
  const [state, dispatch] = useReducer(allReducer, initialState);

  const setData = (data) => {
    dispatch({
      type: "SET_DATA",
      payload: data,
    });
  };

  const setBalance = (balance) => {
    localStorage.setItem("balance", balance);
    dispatch({
      type: "SET_BALANCE",
      payload: balance,
    });
  };

  const setEquity = (equity) => {
    localStorage.setItem("equity", equity);
    dispatch({
      type: "SET_EQUITY",
      payload: equity,
    });
  };

  const setMyShares = (myShares) => {
    localStorage.setItem("myShares", JSON.stringify(myShares));
    dispatch({
      type: "SET_MY_SHARES",
      payload: myShares,
    });
  };

  useEffect(() => {
    setData(data);
    // setInterval(() => {
    //   fetch("http://localhost:3000")
    //     .then((res) => res.json())
    //     .then((data) => {
    //       setData(data);
    //     });
    // }, 30000);
    localStorage.getItem("balance")
      ? setBalance(localStorage.getItem("balance"))
      : setBalance(1000000);
    localStorage.getItem("equity")
      ? setEquity(localStorage.getItem("equity"))
      : setEquity(1000000);
    localStorage.getItem("myShares")
      ? setMyShares(JSON.parse(localStorage.getItem("myShares")))
      : setMyShares([]);
  }, []);

  return (
    <>
      <AllContext.Provider
        value={{
          data: state.data,
          balance: state.balance,
          equity: state.equity,
          myShares: state.myShares,
          setData,
          setBalance,
          setEquity,
          setMyShares,
        }}
      >
        {props.children}
      </AllContext.Provider>
    </>
  );
};

export { ContextProvider, AllContext };
